@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

main {
  display: block;
}

select,
input,
textarea,
button {
  outline: none;
  margin: 0;
  cursor: pointer;
}

textarea,
button,
input[type=text],
input[type=password],
input[type=submit],
input[type=reset],
input[type=tel],
input[type=number] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
  border: solid 1px #333;
  padding: 3px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
  border-radius: 0;
}

button {
  padding: 0;
  border: none;
  overflow: visible;
}

textarea {
  resize: none;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  background: black;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
  overflow-wrap: break-word;
}

.clear,
.clearfix {
  zoom: 100%;
}
.clear:before,
.clearfix:before, .clear:after,
.clearfix:after {
  content: "";
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
}

header .menu_contents nav ul, header .menu_contents nav .sns {
  font-size: 0;
  letter-spacing: 0;
  font-weight: normal;
}

/*
Media Query Mixin
*/
footer .copy {
  font-family: "Heebo", sans-serif;
}

.h2_deco2, header .menu_contents nav ul li a {
  font-family: "Sorts Mill Goudy", serif;
}

html {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #333;
  font-weight: 400;
  font-size: 62.5%;
}

body {
  font-size: 1em;
}
body #wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

input,
button,
textarea {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #333;
}

a {
  color: #333;
  text-decoration: none;
}

.gothic {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

.mincho {
  font-family: YakuHanMP, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.h2_deco2 {
  font-size: 48px;
  letter-spacing: 0.1em;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1.3;
}
.h2_deco2.show:before {
  width: 23%;
}
.h2_deco2:before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background: #333;
  display: block;
  -webkit-transition: all 0.5s 0.3s ease-out;
  transition: all 0.5s 0.3s ease-out;
}
@media screen and (max-width: 768px) {
  .h2_deco2 {
    font-size: 24px;
    line-height: 1.8;
  }
}

.page_contents {
  width: 880px;
  margin: auto;
  display: block;
  position: relative;
}
.page_contents .p_arrow {
  width: 20px;
  height: 20px;
  display: block;
}
.page_contents .p_arrow:before {
  content: "";
  width: 8px;
  height: 8px;
  border: 1.7px solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 28%;
  left: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.page_contents .p_arrow.on_arrow:hover.next:before {
  left: 6px;
  border-color: #A0A0A0 #A0A0A0 transparent transparent;
}
.page_contents .p_arrow.on_arrow:hover.return:before {
  left: -6px;
  border-color: transparent transparent #A0A0A0 #A0A0A0;
}
.page_contents .p_arrow.no_arrow:before {
  opacity: 0.3;
}
.page_contents .p_arrow.next {
  position: absolute;
  top: 0;
  right: 0;
}
.page_contents .p_arrow.next:before {
  border-color: #000 #000 transparent transparent;
}
.page_contents .p_arrow.return {
  position: absolute;
  top: 0;
  left: 0;
}
.page_contents .p_arrow.return:before {
  margin-left: 10px;
  border-color: transparent transparent #000 #000;
}
.page_contents .p_number {
  display: block;
  height: 20px;
  text-align: center;
}
.page_contents .p_number li {
  display: inline-block;
  margin: 0 10px;
}
.page_contents .p_number li a, .page_contents .p_number li p {
  font-size: 20px;
  line-height: 20px;
}
.page_contents .p_number li a {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.page_contents .p_number li a:hover {
  opacity: 0.5;
}
.page_contents .p_number li p {
  color: #8E8E8E;
}
@media screen and (max-width: 768px) {
  .page_contents {
    width: 90%;
    position: relative;
  }
  .page_contents .next {
    position: absolute;
    right: 0;
  }
  .page_contents .return {
    position: absolute;
    left: 0;
  }
  .page_contents .p_arrow {
    width: 15px;
    height: 15px;
  }
  .page_contents .p_arrow:before {
    width: 6px;
    height: 6px;
    top: 28%;
  }
  .page_contents .p_number {
    margin: 0;
    padding: 0 15px;
    position: relative;
    top: -5px;
  }
  .page_contents .p_number li {
    margin: 0 10px;
  }
  .page_contents .p_number li a, .page_contents .p_number li p {
    line-height: 25px;
  }
}

header.open .screen {
  opacity: 1;
  z-index: 99;
}
header.open .menu hr:nth-of-type(1) {
  top: 50%;
  -webkit-transform: rotate(45deg) translate(0, -50%);
          transform: rotate(45deg) translate(0, -50%);
}
header.open .menu hr:nth-of-type(2) {
  opacity: 0;
  margin-left: 10px;
}
header.open .menu hr:nth-of-type(3) {
  top: 50%;
  -webkit-transform: rotate(-45deg) translate(0, -50%);
          transform: rotate(-45deg) translate(0, -50%);
}
header.open .menu_contents {
  left: 0;
}
header .screen {
  content: "";
  background: rgba(51, 51, 51, 0.3);
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -2;
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
header .main_icn {
  position: absolute;
  top: 30px;
  left: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 78px;
  height: auto;
  display: block;
  z-index: 100;
}
header .main_icn:before {
  content: "";
  padding-top: 100%;
  display: block;
}
header .main_icn img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
header .menu {
  position: fixed;
  top: 40px;
  left: 30px;
  display: block;
  width: 36px;
  height: 30px;
  cursor: pointer;
  z-index: 101;
}
header .menu hr {
  width: 100%;
  height: 1px;
  background: #333333;
  display: block;
  position: absolute;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
header .menu hr:nth-of-type(1) {
  top: 27%;
}
header .menu hr:nth-of-type(2) {
  top: 50%;
}
header .menu hr:nth-of-type(3) {
  top: 73%;
}
header .menu_contents {
  position: fixed;
  top: 0;
  left: -50vw;
  width: 50vw;
  height: 100vh;
  background: #fff;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  z-index: 100;
  -webkit-transform: translate3d(0, 0, 1);
          transform: translate3d(0, 0, 1);
}
header .menu_contents nav {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 280px;
}
header .menu_contents nav ul {
  display: block;
}
header .menu_contents nav ul li {
  margin-bottom: 30px;
}
header .menu_contents nav ul li a {
  font-size: 22px;
  letter-spacing: 0.1em;
  position: relative;
}
header .menu_contents nav ul li a:before {
  content: "";
  width: 0%;
  height: 1px;
  display: block;
  position: absolute;
  background: #333;
  bottom: 0px;
  left: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
header .menu_contents nav ul li a:hover:before, header .menu_contents nav ul li a.current:before {
  width: 100%;
}
header .menu_contents nav .sns a {
  width: 30px;
  height: 30px;
  background: #303030;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  border: 1.5px solid #303030;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
header .menu_contents nav .sns a:hover {
  background: #fff;
}
header .menu_contents nav .sns a:hover .cls-3 {
  fill: #303030;
}
header .menu_contents nav .sns a:last-of-type {
  margin-right: 0;
}
header .menu_contents nav .sns a .cls-3 {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
header .menu_contents nav .sns a svg {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  header:before {
    display: none;
  }
  header .main_icn {
    top: 15px;
    width: 12%;
  }
  header .menu {
    top: 20px;
    left: 15px;
    width: 36px;
    height: 30px;
  }
  header .menu_contents {
    left: -100vw;
    width: 100vw;
  }
}

footer {
  background: #fff;
  text-align: center;
  padding: 40px 0 10px;
  position: relative;
  margin-top: auto;
}
footer ul {
  margin-top: 50px;
}
footer ul li {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 20px;
}
footer ul li:hover .cls-1 {
  fill: #fff;
}
footer ul li:hover .cls-2 {
  fill: #333;
}
footer ul li:last-of-type {
  margin-right: 0;
}
footer ul li svg {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
footer .copy {
  margin-top: 50px;
  font-size: 10px;
  letter-spacing: 0.1em;
}
footer .up_arrow {
  width: 60px;
  height: 70px;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
}
footer .up_arrow.show {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
footer .up_arrow:before {
  content: "";
  display: block;
  width: 1px;
  background: #333;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  -webkit-animation: pathmove2 1.4s ease-in-out infinite;
          animation: pathmove2 1.4s ease-in-out infinite;
}
footer .up_arrow:after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border: 1px solid;
  border-color: #333 #333 transparent transparent;
  position: absolute;
  top: 13.5px;
  left: 25.4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@-webkit-keyframes pathmove2 {
  0% {
    height: 0;
    bottom: 0;
    opacity: 0;
  }
  30% {
    height: 53%;
    opacity: 1;
  }
  100% {
    height: 0;
    bottom: 50px;
    opacity: 0;
  }
}
@keyframes pathmove2 {
  0% {
    height: 0;
    bottom: 0;
    opacity: 0;
  }
  30% {
    height: 53%;
    opacity: 1;
  }
  100% {
    height: 0;
    bottom: 50px;
    opacity: 0;
  }
}
@media screen and (max-width: 768px) {
  footer .up_arrow {
    display: none;
  }
}

.video_modal {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.85);
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
  opacity: 0;
}
.video_modal.action {
  left: 0;
  opacity: 1;
}
.video_modal.action.out {
  opacity: 0;
}
.video_modal .video_modal_wrap {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 130px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.video_modal .video_modal_wrap .video_modal_contents {
  width: 980px;
  margin: auto;
  text-align: left;
  color: #fff;
}
.video_modal .video_modal_wrap .video_modal_contents .close {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.video_modal .video_modal_wrap .video_modal_contents .close:hover {
  opacity: 0.6;
}
.video_modal .video_modal_wrap .video_modal_contents .close:before, .video_modal .video_modal_wrap .video_modal_contents .close:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}
.video_modal .video_modal_wrap .video_modal_contents .close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.video_modal .video_modal_wrap .video_modal_contents .close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.video_modal .video_modal_wrap .video_modal_contents .video {
  width: 100%;
  position: relative;
  cursor: pointer;
  background: no-repeat center/cover;
}
.video_modal .video_modal_wrap .video_modal_contents .video:before {
  content: "";
  display: block;
  padding-top: 56.2%;
}
.video_modal .video_modal_wrap .video_modal_contents .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .video_modal .video_modal_wrap {
    padding: 50px 0;
  }
  .video_modal .video_modal_wrap .video_modal_contents {
    width: 100%;
    text-align: center;
    padding: 0 15px;
  }
  .video_modal .video_modal_wrap .video_modal_contents .close {
    width: 22px;
    height: 22px;
    top: 20px;
    right: 20px;
  }
}

.contents_img {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}
.contents_img.show img {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.contents_img:before {
  content: "";
  padding-top: 25.4%;
  display: block;
}
.contents_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  -webkit-transition: all 1.6s ease-out;
  transition: all 1.6s ease-out;
  opacity: 0;
}